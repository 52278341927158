import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInDerSteiermark = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in der Steiermark?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal steht
                    die Steiermark auf dem Programm.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – aktuelle Tendenzen</h2>
                <p>
                    Der Preisanstieg auf dem österreichischen Immobilienmarkt liegt seit dem vierten Quartal 2020 im
                    zweistelligen Bereich. Grund dafür ist eine erhöhte Nachfrage wegen der momentan günstigen
                    Kreditzinsen. 12,3 % betrug der Anstieg im ersten Quartal 2021, im zweiten war eine Teuerung von
                    11,7 % zu beobachten. Während sich der Anstieg der Kaufpreise für Einfamilienhäuser außerhalb Wiens
                    2021 von 12,9 % im ersten auf 11,3 % im zweiten Quartal verringerte, wurden gebrauchte
                    Eigentumswohnungen in letzterem um 14,3 % teurer. Besonders schnell wachsen die Preise jedoch
                    außerhalb Wiens: Während sie in Wien im ersten Quartal 2021 um 10,9 und im zweiten um 10,7 %
                    anstiegen, kam es in den anderen Bundesländern diesem Zeitraum zu Wachstumsraten von 14 bzw. 12,8 %.
                    Insbesondere der Neubauwohnungsmarkt ist von diesem Trend betroffen: Stiegen die Preise für neue
                    Immobilien in Wien 2021 um 10,4 im ersten und von 10, 1 % zweiten Quartal, so kam es außerhalb der
                    Landeshauptstadt zu einem Anstieg von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in der Steiermark</h2>
                <p>
                    Der Immobilienmarkt in der Steiermark figuriert in Fachkreis auch als “Steiermarkt”. Aber Spaß und
                    schlechte Wortwitze einmal beiseite: Was den steirischen Markt tatsächlich auszeichnet, ist ein
                    ausgeprägtes Stadt-Land-Gefälle. Für eine Eigentumswohnung in Graz zahlst du etwa doppelt so viel
                    wie für eine Wohnung in Murau. Interessant ist auch der erhebliche Preisunterschied zwischen neuen
                    und gebrauchten Wohnungen: Erstere sind meist 60 bis 90 % teurer.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das kosten Häuser in der Steiermark</h2>
                <p>
                    Für den steirischen Immobilienmarkt ist, wie bereits angedeutet, ein besonders besonders starkes
                    Stadt-Land-Gefälle charakteristisch: Die Kaufpreise von Einfamilienhäusern sind in Graz etwa doppelt
                    so hoch wie im Umland: 2.131,88 € zahlst du in der Hauptstadt pro Quadratmeter, während es etwa in
                    Murau lediglich 844 € sind. Dazwischen liegen beispielsweise Weiz mit 1.450, Leibnitz mit 1.362,50
                    und Leoben mit 935 € pro Quadratmeter.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInDerSteiermark"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEinHausInDerSteiermark"}
                heading={"Was kostet ein Haus in der Steiermark?"}
            />
        </Layout>
    );
};

export default WasKostetEinHausInDerSteiermark;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-der-steiermark", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
